const React = require('react')
const connect = require('../connect')
const formIdConsumer = require('./form-id-consumer')
const PropTypes = require('prop-types')
const {default: Select} = require('react-select')
const types = require('../../types')

const styles = require('../../styles/forms.css')

class SelectInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    if (this.props.value) {
      return
    }

    const {name, formId} = this.props
    this.props.dispatch({
      type: 'FORM_CHANGE',
      name,
      formId,
      value: this.getSelectedValue(),
    })
  }

  onChange(selectedOption) {
    const {name, formId} = this.props
    const value = selectedOption ? selectedOption?.value : ''
    this.props.dispatch({
      type: 'FORM_CHANGE',
      name,
      formId,
      value,
    })
    this.props.onChange && this.props.onChange(selectedOption)
  }

  getSelectedValue() {
    const {value: defaultValue, formData = {}, values} = this.props
    const value = formData.value || defaultValue
    return values.find(option => option.value === value) || ''
  }

  render() {
    const {name, values, label, className, formId, isMandatory} = this.props
    const id = `${formId}_${name}`
    const selectedValue = this.getSelectedValue()
    return (
      <div className={styles.selectContainer}>
        <label className={styles.label} htmlFor={id} id={`${id}-label`}>
          {label}
          {isMandatory && <abbr>*</abbr>}
        </label>
        <Select
          id={id}
          name={name}
          className={`${styles.selectElement} ${className}`}
          value={selectedValue}
          options={values}
          onChange={this.onChange}
          menuPlacement="auto"
          menuPosition="fixed"
          aria-labelledby={`${id}-label`}
          styles={{
            menu: provided => ({
              ...provided,
              zIndex: 999,
            }),
          }}
          isClearable={false}
        />
      </div>
    )
  }
}

SelectInput.propTypes = {
  formId: PropTypes.string,
  value: PropTypes.string,
  formData: types.formDatum,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isMandatory: PropTypes.bool,
}

module.exports = connect()(formIdConsumer(SelectInput))
